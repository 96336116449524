import { useEffect } from "react";

const RedirectPage = () => {
  useEffect(() => {
    let dl_found = false;

    const detectDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) return "android";
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "ios";
      return "desktop";
    };

    const isSafari = () => {
      const userAgent = navigator.userAgent;
      return /safari/i.test(userAgent) && !/CriOS|FxiOS/i.test(userAgent);
    };

    const isMobileBrowser = () => {
      const userAgent = navigator.userAgent;
      return /CriOS|FxiOS|chrome.*mobile|firefox.*mobile|opera.*mobile/i.test(userAgent) && !/safari/i.test(userAgent);
    };

    const isFbBrowser = () => {
      const userAgent = navigator.userAgent;
      return /FBAN|FBAV/i.test(userAgent);
    };

    const isChromeMob = () => {
      const userAgent = navigator.userAgent;
      return /CriOS/i.test(userAgent);
    };

    const redirect = () => {
      const device = detectDevice();
      const redirectUrl = "https://onlyfans.com/pr0xyproject/c22";
      let timeoutId, timeoutId2, timeoutId3, timeoutId4;

      const performRedirect = () => {
        if (device === "desktop") {
          window.location.replace(redirectUrl);
        } else {
          const tryOpenApp = () => {
            if (dl_found) {
              setTimeout(() => {
                window.location = redirectUrl;
              }, 300);
            }

            if (!isMobileBrowser() && (!isFbBrowser() || (isFbBrowser() && device === "android"))) {
              timeoutId = setTimeout(() => {
                window.location = `googlechrome://${redirectUrl}`;
              }, 500);
            }

            if (device === "ios") {
              timeoutId2 = setTimeout(() => {
                window.location = `x-safari-${redirectUrl}`;
              }, 500);

              timeoutId3 = setTimeout(() => {
                if (!isSafari()) {
                  window.location = `googlechrome://${redirectUrl}`;
                }
              }, 800);
            }

            timeoutId4 = setTimeout(() => {
              window.location = redirectUrl;
            }, 1100);
          };

          const handleVisibilityChange = () => {
            if (document.hidden) {
              clearTimeout(timeoutId);
              clearTimeout(timeoutId2);
              clearTimeout(timeoutId3);
              clearTimeout(timeoutId4);
            }
          };

          document.addEventListener("visibilitychange", handleVisibilityChange);
          tryOpenApp();
        }
      };

      performRedirect();
    };

    redirect();
  }, []);

  return (
    <div style={styles.container}>
      <p style={styles.pb}>
        <a href="https://onlyfans.com/pr0xyproject/c22"></a>
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    fontSize: "16px",
    width: "100%",
    maxWidth: "100%",
    padding: "1em",
    margin: 0,
    boxSizing: "border-box",
    fontFamily: "sans-serif",
    overflowX: "hidden",
  },
  pb: {
    display: "block",
    width: "100%",
    position: "fixed",
    bottom: "1em",
    left: 0,
    right: 0,
    fontSize: "0.8em",
  },
};

export default RedirectPage;
